<template>
  <div class="glucometr">
    <div class="wrapper">
      <img :src="require('@/assets/glucometr_4.svg')">
      <div class="container">
        <div class="title">
          <h2>Glucose converter</h2>
        </div>
        <div class="display block-mg">
          <div class="value">
            <input class="digital-type" type="text"
              aria-label="Value in mg"
              v-model="mg"
              @keyup="calculate(true)"
              >
          </div>
          <div class="desc digital-type">
            mg\dl (mg%)
          </div>
        </div>
        <div class="display block-mm">
          <div class="value">
            <input class="digital-type" type="text"
              aria-label="Value in mmol"
              v-model="mm"
              @keyup="calculate()"
              >
          </div>
          <div class="desc digital-type">
            mmol\l
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data: () => ({
    mg: '0.123456',
    mm: '1.654321'
  }),
  props: {
    msg: String
  },
  methods: {
    calculate (reverse) {
      this.$nextTick(() => {
        if (reverse === true) {
          this.mg = this.mg.replace(/,/g, '.')
          this.mm = Math.ceil(parseFloat(this.mg) / 18 * 100) / 100
        } else {
          this.mm = this.mm.replace(/,/g, '.')
          this.mg = Math.floor(this.mm * 18 * 10) / 10
          this.mg = Math.round(this.mg)
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.digital-type {
  font-family: "VT323", Avenir, Helvetica, Arial, sans-serif;
  font-size: 2em;
}
input.digital-type,input.digital-type:focus,input.digital-type:focus-visible {
  border: none;
  background: none;
  width: 100%;
  outline: none;
}
.wrapper {
  min-width: 300px;
  width: fit-content;
  position: relative;
  margin: 0 auto;
  max-height: 95vh;
  min-height: 95vh;
  background: url('~@/assets/glucometr_4.svg') no-repeat;
  background-size: contain;
}
.absolute {
  position: absolute;
}
.container {
  width: 100%;
  height: 56%;
  position: absolute;
  top: 30%;
  left: -0.6%;
}

.display {
  background: #b3c2af;
  position: relative;
  border: 2pt solid black;
  border-radius: 17pt;
  margin: 0 auto;
  width: 80%;
  left: 1px;
  padding: 0 4%;
  box-shadow: inset 5px 3px 19px -1px rgba(130,144,132,1);
  color: #43443f;
}

.title {
  margin: -7% 0 -19% 0;
}

.block-mg {
  top: 20%;
}
.block-mm {
  top: 26%;
}
.block-mg, .block-mm {
  text-align: left;
  height: 80px;
}
.desc {
  position: absolute;
  top: 53%;
  right: 5%;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.glucometr .wrapper img {
  visibility: hidden;
  max-height: 95vh;
  width: auto;
  max-width: 100%;
}

@media (max-height: 540px) {
  .container {
    top: 25%;
    scale: 0.75;
  }
}
</style>
